<div class="container-fluid bg purchase-summary-main">
    <app-header></app-header>
    <div class="container-fluid">
        <div class="crunch-box-bar">
            <div class="crunch-head">CRUNCHBOX TOOLS</div>
        </div>
        <div class="crunch-item-bg">
            <div class="crunch-exp">SESSION EXPIRED</div>
            <label class="crunch-content"><b class="return-timeout">Return to the CGX Flowpoint application</b> <br>
               <div class="crunch-returntext"> For your security, we've implemented a 15 minute idle time-out on this CrunchBox portal. To begin a new
                CrunchBox portal session, return to your CGX Flowpoint desktop application, log out, and begin the log
                in process once again.</div></label>
        </div>
    </div>
</div>