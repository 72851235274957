<div class="container-fluid bg purchase-summary-main">
    <app-header></app-header>
    <div class="container-fluid">
        <div class="crunch-box-bar">
            <div class="crunch-head">CRUNCHBOX TOOLS</div>
        </div>
        <div class="crunch-item-bg">
            <label class="crunch-exp">THE CRUNCHBOX MARKETPLACE</label>
            <hr class="borderCss">
            <label class="crunch-body"> First-In-Class applications developed by neuroscience leaders</label>
            <label class="crunch-content"> CrunchBox hosts research and clinical tools written in Python and MATLAB run on the CrunchBox server. CrunchBox tool authors are 
                responsible for the content and output of their algorithms, while CGX verifies CrunchBox tools operate as intended and adhere to the author's 
                data privacy requirements.</label>
            <label class="crunch-body2">Contact CGX</label>
            <label class="crunch-content2">Contact <a
                    href="mailto:ira@cgxsystems.com?subject=CrunchBox Marketplace Discussion" target="_self"
                    class="mailText">Ira Friedman</a> at CGX to discuss hosting your work on the CrunchBox Marketplace.
            </label>
        </div>
    </div>
</div>
<app-footer></app-footer>