<!--end container-fluid-->
<!-- <app-footer></app-footer> -->
<div class="container-fluid bg purchase-summary-main">
    <app-header></app-header>
    <div class="container-fluid">
        <div class="crunch-box-bar">
            <div class="crunch-head">PROFILE</div>
        </div>
        <div class="crunch-item-bg">
            <label class="crunch-exp">Password Changed Successfully</label>
            <hr class="borderCss">
            <label class="crunch-body">Please go to flowpoint for login</label>
            <!-- <label class="crunch-content">Please go to flowpoint for login</label> -->
            </div>
    </div>
</div>