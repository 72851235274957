<div class="container-fluid bg purchase-summary-main">
    <app-header></app-header>
    <div class="container-fluid">
        <div class="crunch-box-bar">
            <div class="crunch-head">CRUNCHBOX TOOLS</div>
        </div>
        <div class="crunch-item-bg">
            <div class="crunch-exp">RESET SUCCESSFUL</div>
            <label class="crunch-body">Please go to flowpoint for login</label>
            <!-- <label class="crunch-content"><b class="return-timeout">Return to the CGX Flowpoint application</b> <br> -->
               <!-- <div class="crunch-returntext"> Your password has been successfully changed.</div></label> -->
        </div>
    </div>
</div>