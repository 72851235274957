<footer class="page-footer purchase-summary-main">
    <div class="text-center footer-container">
        <div class="row footer-information">
            <div class="ml-auto footer-card">
                <h5 class="footer-head">CrunchBox Tools</h5>
                <ul class="list-unstyled">
                    <li>
                        <a [routerLink]="['/authorizenewcrunch']" class="footer-text"> All CrunchBox Tools<br></a>
                            <a class="footer-text" [routerLink]="['/marketplace']">  Become A CrunchBox Contributor</a>
                    </li>
                </ul>
            </div>
            <div class="footMid footer-card">
                <h5 class="footer-head">Account</h5>
                <ul class="list-unstyled">
                    <li>
                        <a [routerLink]="['/profileinformation']" class="footer-text">
                            Account Information</a><br>
                            <a [routerLink]="['/add-card']" class="footer-text">
                                Add Payment Method</a>
                    </li>
                </ul>
            </div>
            <div class="mr-auto footer-card">
                <h5 class="footer-head">Contact</h5>
                <ul class="list-unstyled">
                    <li>
                        <a class="footer-text" target="_blank" href="https://www.cgxsystems.com/contact"> Contact Information <br></a>
                        <a class="footer-text" target="_blank" href="https://www.cgxsystems.com/privacy-statement"> Data Use and Privacy
                            Policy</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>