<p-toast position="bottom-right" key="forgotPassKey"></p-toast>
<div class="container-fluid purchase-summary-main">
    <div class="bg-image">
        <div class="">
            <div class="wrapper forgot-wrapper">
                <div class="box box-forgot-password">
                    <div class="topbar">
                        <img src="../../assets/images/logo.png" class="forgotlogo" />
                    </div>

                    <form (ngSubmit)="frm.form.valid && onSubmit(frm);" #frm="ngForm" novalidate>
                        <div class="form-box">

                            <h1>Forgot Password</h1>
                            <p class="password-link"> We'll Send You An Email With A Reset Password Link</p>

                            <div class="form-group">
                                <input class="form-control email-forgot" type="email" placeholder="Email Address"
                                    [(ngModel)]="forgotPassword.email" name="email" required email #emailRef="ngModel"
                                    [ngClass]="{ 'is-invalid': frm.submitted && emailRef.invalid }">
                                <div *ngIf="frm.submitted && emailRef.invalid" class="invalid-feedback">
                                    <div *ngIf="emailRef.errors.required">Email is required</div>
                                    <div *ngIf="emailRef.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                            <button type="submit" class="btn button-blue forgot-blue-btn text-white">Submit</button>
                            <!-- <p class="bold already-link">Already Have An Account? <a routerLink='/signin' class="forgot-sign-link">Sign In Here</a></p> -->

                        </div>
                    </form>
                </div>
                <!--end Form box-->

            </div>
            <!--end wrapper-->

        </div>
        <!--end container-->
    </div>
    <!--end bg image-->
</div>
<!--end container-fluid-->