<p-toast position="bottom-right" key="resetKey"></p-toast>
<div class="container-fluid">
    <div class="bg-image">
        <div class="=">
            <div class="wrapper reset-wrapper">
                <div class="box box-reset-password">
                    <!-- <div class="resettfrm"> -->
                    <div class="topbar">
                        <img src="../../assets/images/logo.png" class="resetlogo" />
                    </div>

                    <form (ngSubmit)="resetFrm.form.valid && onSubmit(resetFrm);" #resetFrm="ngForm" novalidate
                        [mustMatch]="['password', 'confirm_password']">
                        <div class="form-box">

                            <h2>Set New Password</h2>

                            <input type="hidden" value="{{token}}" name="token" [(ngModel)]="token">
                            <div class="form-group inner-addon">

                                <input class="form-control pwd-control reset-input input-field-r-pad"
                                    placeholder="New Password" [type]="showPassword ? 'text' : 'password'"
                                    name="password" [(ngModel)]="password" #passwordRef="ngModel"
                                    [ngClass]="{ 'is-invalid': resetFrm.submitted && passwordRef.invalid }" required
                                    minlength="6">

                                <div>
                                    <i alt="show" class="eye-show reset-eye-one" (click)="showPassword = !showPassword"
                                        [class.hide]="!showPassword"></i>
                                    <i alt="hide" class="eye-hide reset-eye-one" (click)="showPassword = !showPassword"
                                        [class.hide]="showPassword"></i>
                                </div>
                                <div *ngIf="resetFrm.submitted && passwordRef.invalid" class="invalid-feedback">
                                    <div *ngIf="passwordRef.errors.required">Password is required</div>
                                    <div *ngIf="passwordRef.errors.minlength">Minimum length is 6 characters</div>
                                </div>
                            </div>
                            <div class="form-group inner-addon">
                                <input [type]="showConfirmPassword ? 'text' : 'password'"
                                    class="form-control pwd-control reset-input-two input-field-r-pad"
                                    name="confirm_password" placeholder="Confirm Password"
                                    [(ngModel)]="confirm_password" #confirm_pass="ngModel"
                                    [ngClass]="{ 'is-invalid': resetFrm.submitted && confirm_pass.invalid }" required />

                                <div>
                                    <i alt="show" class="eye-show  reset-eye-two"
                                        (click)="showConfirmPassword = !showConfirmPassword"
                                        [class.hide]="!showConfirmPassword"></i>
                                    <i alt="hide" class="eye-hide reset-eye-two"
                                        (click)="showConfirmPassword = !showConfirmPassword"
                                        [class.hide]="showConfirmPassword"></i>
                                </div>
                                <div *ngIf="resetFrm.submitted && confirm_pass.invalid" class="invalid-feedback">
                                    <div *ngIf="confirm_pass.errors.required">Confirm Password is required</div>
                                    <div *ngIf="confirm_pass.errors.mustMatch">Passwords must match</div>
                                </div>
                            </div>
                            <button type="submit" class="btn button-blue reset-blue-btn text-white">Reset
                                Password</button>
                            <!-- <p class="bold login-new-user">Already Have An Account? <a routerLink='/signin'>Sign In Here</a></p> -->
                        </div>
                    </form>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="blocked_reset_Wait">
        <div class="loader" id="loaderSelection" role="dialog">
            <img src="assets/images/loader.gif" alt="Loader">
        </div>
    </div>
</div>