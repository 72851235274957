<div class="container-fluid bg purchase-summary-main">
    <app-header></app-header>
    <div class="container-fluid">
        <div class="crunch-box-bar">
            <div class="crunch-head">PURCHASE SUMMARY</div>
        </div>
        <div class="crunch-item-bg">
            <label class="crunch-exp">Payment Successful</label>
            <hr class="borderCss">
            <label class="crunch-body">Please check your email for payment receipt</label>
            <!-- <label class="crunch-content">Please go to flowpoint for login</label> -->
            </div>
    </div>
</div>
<app-footer></app-footer>